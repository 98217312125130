.footer {
  width: 100%;
  background-color: #a243b5;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 8px 0px;
  padding: 1rem 0;

  .footer_container {
    max-width: 75rem;
    margin: 1rem auto;
    display: flex;
    justify-content: space-around;

    .logo {
      width: 300px;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }

      .link_logo {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 2rem;

        a {
          width: 20%;
          height: 100%;

          img {
            height: 35px !important;
            width: 35px !important;
            display: block;
            margin: 0 auto;
          }

          span {
            color: #fff;
            font-size: 12px;
            display: block;
            text-align: center;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .footer_bottom {
    padding: 3px 0 0;
    text-align: center;
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    .footer_container {
      flex-wrap: wrap;

      div {
        width: 45% !important;
        margin-bottom: 1rem;
      }

      .logo {
        .link_logo {
          width: 100% !important;

          a {
            img {
              width: 25px !important;
              height: 25px !important;
            }

            span {
              zoom: 0.7;
            }
          }
        }
      }
    }
  }
}

.review {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #000000;
}

.reviewDesc {
  margin-left: 0.8rem;
  margin-top: 0.5rem;
  font-size: 0.875rem; /* text-sm */
  color: white; /* text-[#B4B4B4] */
}
